const activeView = localStorage.getItem('activeViewSet')
  ? JSON.parse(localStorage.getItem('activeViewSet'))
  : null

export const initialState = {
  data_failure: false,
  locations: null,
  active_location: null,
  company: null,
  // location: null,
  active_location_dfs_summary: null,
  kiosks: null,
  active_view: activeView ?? {
    id: 0,
    name: 'All Companies',
    type: 'u'
  },
}

export const LocationReducer = (initialState, action) => {
  const { type, payload } = action
  // Leaving for future Debug
  // console.log(type)
  // console.log(payload)

  switch (type) {
    case 'ACTIVE_VIEW_SET':
      return {
        ...initialState,
        active_view: payload.view,
      }
    case 'UPDATE_ACTIVE_LOCATION':
      return {
        ...initialState,
        active_location: payload.active_location,
      }
    case 'UPDATE_LOCATIONS':
      return {
        ...initialState,
        locations: payload.locations,
      }
    case 'UPDATE_COMPANY':
      return {
        ...initialState,
        company: payload.company,
      }
    case 'UPDATE_ACTIVE_LOCATION_DFS_SUMMARY':
      return {
        ...initialState,
        active_location_dfs_summary: payload.dfs_summary,
      }
    case 'UPDATE_KIOSKS':
      return {
        ...initialState,
        kiosks: payload.kiosks,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

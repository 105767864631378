import React, { useEffect, useState } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Badge,
  Card,
  Button
} from 'react-bootstrap'
import Flex from 'components/common/Flex'
import { useLocationContext } from 'context/Location'
import { currencyOptions, dateTimeOptions } from 'helpers/formater'
import ChangeRetailPrice from '../pricing/EditRetailPrice'
import classNames from 'classnames'

const CurrentRetail = () => {
  const { locationState } = useLocationContext()
  const [location, setLocation] = useState(null)
  const [retailPrice, setRetailPrice] = useState('0')
  const [retailPriceDateRaw, setRetailPriceDateRaw] = useState(new Date())
  const [retailPriceRaw, setRetailPriceRaw] = useState(0)
  const [retailPriceDate, setRetailPriceDate] = useState('0')
  const [showRetailPriceModal, setShowRetailPriceModal] = useState(false)

  // today minus one day of milliseconds
  const oldestDate = new Date(Date.now() - 86400000)

  useEffect(() => {
    if (locationState.active_location !== null) {
      setLocation(locationState.active_location)
    }
  }, [locationState.active_location])

  useEffect(() => {
    if (location === null) {
      setRetailPriceDateRaw(new Date())
    } else {
      const retailDate = new Date(location.retail_date)
      if (location.retail_date !== null && location.retail_price !== null && location.retail_price > 0) {
        setRetailPrice(
          new Intl.NumberFormat('default', currencyOptions).format(location.retail_price)
        )
        setRetailPriceRaw(
          location.retail_price
        )
      }

      if (location.retail_date !== null && location.retail_date !== '') {
        setRetailPriceDate(
          new Intl.DateTimeFormat('en-US', dateTimeOptions).format(retailDate)
        )
        setRetailPriceDateRaw(retailDate)
      }
    }
  }, [location])

  return (
    <>
      <ChangeRetailPrice retailPrice={retailPriceRaw} show={showRetailPriceModal} hide={() => setShowRetailPriceModal(false)} />

      <Card className='h-md-100'>
        <Card.Header className='pb-0'>
          <h6 className='mb-0 mt-2'>Current Retail Price</h6>
        </Card.Header>

        <Card.Body
          as={Flex}
          alignItems='end'
          justifyContent='between'
          className='pt-0'
        >
          <div style={ { marginBottom: '1rem' } }
            className={
                classNames({
                  'price-outdated card-data': retailPriceDateRaw.getTime() < oldestDate.getTime()
                })
              }>
            <h2 className='fw-normal text-700 mb-1 lh-1'>{retailPrice}</h2>
            <Badge pill bg='200' className='text-primary fs--2' title='Retail price last changed date'>
              {
                retailPriceDate !== '0' && retailPriceDate !== null && retailPriceDate !== ''
                  ? retailPriceDate
                  : ''
              }
            </Badge>
          </div>
          <div className='ps-0'>
            <Button
              variant='warning'
              type='button'
              size="sm"
              onClick={() => setShowRetailPriceModal(true)}
            >
              Edit
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default CurrentRetail

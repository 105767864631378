import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccountContext } from 'context/Account'

const ForgotForm = ({ hasLabel, success }) => {
  const { forgot } = useAccountContext()
  // State
  const [isProcessing, setProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const [formData, setFormData] = useState({
    email: ''
  })

  useEffect(() => {
    if (
      formData.email
    ) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault()
    setProcessing(true)

    try {
      // eslint-disable-next-line
      const data = await forgot(formData.email)
      console.log(data)
      success(formData.email)
    } catch (error) {
      // console.log('error signing up:', error)
      setProcessing(false)
      toast.error(
        'We\'re having troubles processing your request! Please try again later.',
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    }
  }

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='mb-3'>
        {hasLabel && <Form.Label>Email Address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email Address' : ''}
          name='email'
          onChange={handleFieldChange}
          type='text'
          value={formData.email}
        />
      </Form.Group>

      <Form.Group>
        <Button
          type='submit'
          color='primary'
          className='mt-3 w-100'
          disabled={!isSubmitable}
        >
          {isProcessing
            ? (
              <FontAwesomeIcon
                icon='circle-notch'
                className='me-1 fa-spinner fa-spin'
              />
              )
            : (
                ''
              )}{' '}
          Submit
        </Button>
      </Form.Group>
    </Form>
  )
}

ForgotForm.propTypes = {
  success: PropTypes.func,
  hasLabel: PropTypes.bool
}

ForgotForm.defaultProps = {
  success: null,
  hasLabel: false
}

export default ForgotForm

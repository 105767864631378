import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Card, Dropdown, ListGroup } from 'react-bootstrap'
import { isIterableArray } from 'helpers/utils'
import FalconCardHeader from 'components/common/FalconCardHeader'
import Notification from 'components/notification/Notification'
import { useNotificationContext } from 'context/Notification'

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  // eslint-disable-next-line
  const [isAllRead, setIsAllRead] = useState(false)
  const [newNotifications, setNewNotifications] = useState([])
  // const [earlierNotifications, setEarlierNotifications] = useState([])

  const { notificationState } = useNotificationContext()

  useEffect(() => {
    console.log(notificationState.newNotifications)
    setNewNotifications(notificationState.newNotifications)
  }, [notificationState.newNotifications])

  // useEffect(() => {
  //   console.log(notificationState.earlierNotifications)
  //   setEarlierNotifications(notificationState.earlierNotifications)
  // }, [notificationState.earlierNotifications])

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false)
    })
  }, [])

  return (
    <Dropdown navbar as='li' show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix='toggle'
        as={Link}
        to='#!'
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon='bell' transform='shrink-6' className='fs-4' />
      </Dropdown.Toggle>

      <Dropdown.Menu className='dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg'>
        <Card
          className='dropdown-menu-notification dropdown-menu-end shadow-none'
          style={{ maxWidth: '20rem' }}
        >
          <FalconCardHeader
            className='card-header'
            title='Notifications'
            titleTag='h6'
            light={false}
            // endEl={
            //   <Link
            //     className='card-link fw-normal'
            //     to='#!'
            //     onClick={markAsRead}
            //   >
            //     Mark all as read
            //   </Link>
            // }
          />
          <ListGroup
            variant='flush'
            className='fw-normal fs--1 scrollbar'
            style={{ maxHeight: '19rem' }}
          >
            {/* <div className='list-group-title'>NEW</div>{' '} */}
            {isIterableArray(newNotifications) &&
              newNotifications.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
            {/* <div className='list-group-title'>EARLIER</div>
            {isIterableArray(earlierNotifications) &&
              earlierNotifications.map(notification => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))} */}
          </ListGroup>
          <div
            className='card-footer text-center border-top'
            onClick={handleToggle}
          >
            <Link className='card-link d-block' to='#!'>
              View all
            </Link>
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default NotificationDropdown

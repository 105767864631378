import team1 from 'assets/img/team/1.jpg'

export const initialState = {
  newNotifications: [
    {
      id: 1,
      avatar: {
        src: team1,
        size: '2xl'
      },
      children: 'Retail Price confirmation',
      time: '2022-10-24T18:53:19.042Z',
      className: 'rounded-0 border-x-0 border-300 border-bottom-0',
      to: '#!',
      unread: true
    },
    {
      id: 2,
      avatar: {
        name: 'Albert Brooks',
        size: '2xl'
      },
      children:
        "<strong>Albert Brooks</strong> reacted to <strong>Mia Khalifa's</strong> status",
      time: '2011-10-05T14:48:00.000Z',
      className: 'rounded-0 border-x-0 border-300 border-bottom-0',
      to: '#!',
      unread: true
    }
  ],
  earlierNotifications: [],
}

export const NotificationReducer = (initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case 'ADD_NOTIFICATION':
      return {
        ...initialState,
        newNotifications: payload.value
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

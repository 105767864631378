import {dateMinusHours, getRandomInteger, getRandomNumber, randomString} from 'helpers/utils'

export const config = {
  "dfs":{
    "defaultView":"daily",
    "enabled":true
  }
}

export const companies = [
  {
    "companyid": 3781,
    "companylegalname": "DFS Demo Corp",
    "locations": [
      {
        "location_id": 1272,
        "location_name": "Demotown Truck Stop",
        "address": "2929 W. Navigator Dr.",
        "city": "Meridian",
        "state": "ID",
        "zip": "83642",
        "latitude": "",
        "longitude": "",
        "location": "lost",
        "phone": "",
        "retail_date": dateMinusHours(30),
        "retail_price": 4.594,
        "type": "R",
        "options": {
          "dfs": {
            "enabled": true
          },
          "public": {
            "enabled": true
          },
          "retail_edit": {
            "enabled": true
          },
          "rewards": {
            "enabled": false
          }
        }
      },
      {
        "location_id": 1273,
        "location_name": "Fast Demo Truck Stop",
        "address": null,
        "city": null,
        "state": "AZ",
        "zip": null,
        "latitude": "",
        "longitude": "",
        "location": "lost",
        "phone": "",
        "retail_date": dateMinusHours(3),
        "retail_price": 3.989,
        "type": "R",
        "options": {
          "dfs": {
            "enabled": true
          },
          "public": {
            "enabled": false
          },
          "retail_edit": {
            "enabled": true
          },
          "rewards": {
            "enabled": true
          }
        }
      }
    ]
  }
]

export const kiosks = [
  {
    "kiosk_id": 88,
    "location_id": 1273,
    "kiosk_retail_edit": 0
  }
]

export const gallonsPerDay = (month, year) => {
  const date = new Date(year, month, 1)
  const days = []
  while (date.getMonth() === month) {
    days.push({
      "completed_date":  new Date(date).toISOString().split('T')[0],
      "gallons_count": getRandomNumber(0, 5000, 4)
    })
    date.setDate(date.getDate() + 1)
  }
  return days
}

export const getTransactions = (t) => {
  const retVal = []
  let reportNum = getRandomInteger(1000,10000)
  let reportHourOffset = getRandomInteger(1,5)

  switch (t) {
    case 'redemptions':
      for (let i=0; i < 60; i++) {
        const reportDate = dateMinusHours(reportHourOffset + i)
        retVal.push({
            "tp_order_id": randomString(8).toUpperCase(),
            "location_name": "Fast Demo Truck Stop",
            "pump": getRandomInteger(1,10),
            "gallons": getRandomNumber(100, 400, 2),
            "completed_on": reportDate,
            "retail": getRandomNumber(4, 5, 3),
          })
        reportNum = getRandomInteger(reportNum,10000)
      }
      return retVal

    case 'reservations':
      for (let i=0; i < 5; i++) {
        const reportDate = dateMinusHours(reportHourOffset + i)
        retVal.push({
            "tp_order_id": randomString(8).toUpperCase(),
            "location_name": "Fast Demo Truck Stop",
            "pump": "2",
            "reserved_on": reportDate,
          })
        reportNum = getRandomInteger(reportNum,10000)
      }
      return retVal
  }
}

export const getReports = (type, count, locs) => {
  const retVal = []
  // let reportNum = getRandomInteger(1000,10000)
  let reportNum;
  let reportHourOffset = getRandomInteger(1,10)

  for (let i=0; i < count; i++) {
    // Let's hard-code the report number here depending on the report type, so that
    // we can generate the appropriate date in getReport() based on the report id.
    switch (type) {
      case 'dfs_location_weekly_settlement':
        reportNum = getRandomInteger(1000,3000);
        break;
      case 'dfs_merchant_weekly_summary':
        reportNum = getRandomInteger(3001,6000);
        break;
      case 'dfs_location_daily':
        reportNum = getRandomInteger(6001,9000);
        break;
    }

    const reportDate = dateMinusHours(reportHourOffset + i)
    retVal.push({
      "report_tracker_id": reportNum,
      "report_type": type,
      "report_id": getRandomInteger(1,50),
      "report_datetime": reportDate,
      "report_metadata": {},
      "report_sender_id": -1,
      "lid": "3784",
      "locationname": "Fast Demo Truck Stop",
      "date": reportDate
    })
  }

  return retVal
}

/**
 * Fake the generation of one of the dfs report - fake the data based on the report id, which
 * designates the report type.
 * @param id
 * @param action
 * @returns {[{report_id: *, report_tracker_id, report_metadata: {}, report_datetime: *, report_sender_id: number}]}
 */
export const getReport = (id, action) => {
  let oMetadata = {};
  let reportNum = parseInt(id);
  let reportSentDate = dateMinusHours(getRandomInteger(1,10));

  switch (true) {
    case (reportNum <= 3000):
      oMetadata = generateReportMetadata('dfs_location_weekly_settlement', reportSentDate);
      break;
    case (reportNum <= 6000):
      oMetadata = generateReportMetadata('dfs_merchant_weekly_summary', reportSentDate);
      break;
    case (reportNum <= 9000):
      oMetadata = generateReportMetadata('dfs_location_daily', reportSentDate);
      break;
  }

  return [
    {
      "report_tracker_id": id,
      "report_id": getRandomInteger(1,50),
      "report_datetime": reportSentDate,
      "report_metadata": oMetadata,
      "report_sender_id": -1,
    }
  ]
}

/**
 * fuzzifies a given float number by adding a random decimal value between -0.5 and 0.5 to it:
 * @param num the number to fuzzify
 * @param variant
 * @param dec the number of digits past the decimal point.
 * @returns {string}
 */
export const fuzzifyNumber = (num, variant = 0.5, dec = 2) => {
  // Generate a random decimal between -0.5 and 0.5
  let randomDecimal = Math.random() - variant;
  // Add the random decimal to the original number and return the fuzzified number.
  return (num + randomDecimal).toFixed(dec);
}

/**
 * Given two dates/timestamps, will return a random date between the two.
 * @param sStartDate
 * @param sEndDate
 * @returns {Date}
 */
export const fuzzyDateTimeStamp = (sStartDate, sEndDate) => {
  let start = new Date(sStartDate);
  let end = new Date(sEndDate);
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

/**
 * Makes fake data for the location daily summary reports.
 * @param sStartDate
 * @returns {{total, galTotal, rows: string}}
 */
export const generateFakeLocationDailySummaryData = (sStartDate) => {
  let transactionCount = getRandomInteger(2, 20);
  let aSummaries = [];
  let aRows = [];
  sStartDate = new Date(sStartDate).setHours(0, 0, 0);
  let sEndDate = new Date(sStartDate).setHours(23, 59, 59);

  aSummaries['galSold'] = 0;
  aSummaries['grossTotal'] = 0;
  aSummaries['dfsTotal'] = 0;
  aSummaries['trxFee'] = 0;
  aSummaries['netTotal'] = 0;

  for(let i = 0;  i <= transactionCount; i++) {
    let trxId = (Math.random() + 1).toString(36).substring(7).toUpperCase();
    let id = getRandomInteger(10000,20000);
    let galSoldFirst = getRandomInteger(50, 380);
    let galSoldLast = getRandomInteger(1,300);
    let galSold = parseFloat(galSoldFirst + '.' + galSoldLast);
    let retail = fuzzifyNumber(3.858, 0.2, 3);
    let grossTotal = galSold * retail;
    let dfsRate = fuzzifyNumber(2.826, 0.1, 3);
    let dfsTotal = galSold * dfsRate;
    let trxFee = (dfsRate * 0.004) * galSoldFirst;
    let sEndDate = sStartDate;
    let netTotal = dfsTotal - trxFee;

    let tmpStartDate = fuzzyDateTimeStamp(new Date(sStartDate), new Date(sEndDate));
    let tmpEndDate = fuzzyDateTimeStamp(tmpStartDate, new Date(sEndDate));

    let sRow = "<tr style=\u0027background-color: #ffffff;\u0027>" +
      "<td style=\u0027padding: 5px;\u0027>" + trxId + "<\/td>" +
      "<td style=\u0027padding: 5px;\u0027>" + id + "<\/td>" +
      "<td style=\u0027padding: 5px;\u0027>" + tmpStartDate.toLocaleDateString() + ' ' + tmpStartDate.toLocaleTimeString() + "<\/td>" +
      "<td style=\u0027padding: 5px;\u0027>" + tmpEndDate.toLocaleDateString() + ' ' + tmpEndDate.toLocaleTimeString() + "<\/td>" +
      "<td style=\u0027padding: 5px;text-align: right;\u0027>" + galSold.toFixed(3) + "<\/td>" +
      "<td style=\u0027padding: 5px;text-align: right;\u0027>" + retail + "<\/td>" +
      "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + grossTotal.toFixed(2) + "<\/td>" +
      "<td style=\u0027padding: 5px;text-align: right;\u0027>" + dfsRate + "<\/td>" +
      "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + dfsTotal.toFixed(2) + "<\/td>" +
      "<td style=\u0027padding: 5px;text-align: right;\u0027>($" + trxFee.toFixed(2) + ")<\/td>" +
      "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + netTotal.toFixed(2) + "<\/td>" +
      "<\/tr>";

    aRows.push(sRow);
    aSummaries['galSold'] = parseFloat(aSummaries['galSold']) + parseFloat(galSold.toFixed(3));
    aSummaries['grossTotal'] = parseFloat(aSummaries['grossTotal']) + parseFloat(grossTotal.toFixed(2));
    aSummaries['dfsTotal'] = parseFloat(aSummaries['dfsTotal']) + parseFloat(dfsTotal.toFixed(2));
    aSummaries['trxFee'] = parseFloat(aSummaries['trxFee']) + parseFloat(trxFee.toFixed(2));
    aSummaries['netTotal'] = parseFloat(aSummaries['netTotal']) + parseFloat(netTotal.toFixed(2));
  }
  let sSummaryRow = "<tr style='background-color: #cccccc;'>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>" + parseFloat(aSummaries['galSold']).toFixed(3) + "</td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['grossTotal']).toFixed(2) + "</td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['dfsTotal']).toFixed(2) + "</td>" +
    "<td style='padding: 5px; text-align: right;'>($" + parseFloat(aSummaries['trxFee']).toFixed(2) + ")</td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['netTotal']).toFixed(2) + "</td>" +
    "</tr>";
  aRows.push(sSummaryRow);

  return {
    rows: aRows.join(''),
    galSold: parseFloat(aSummaries['galSold']).toFixed(3),
    dfsTotal: parseFloat(aSummaries['dfsTotal']).toFixed(2)
  };
}

/**
 * Generate fake report data for Location Weekly Settlements
 * @param sStartDate
 * @param sEndDate
 * @returns {{achTotal: string, rows: string}}
 */
export const generateFakeMerchantWeeklySummaryData = (sStartDate, sEndDate) => {
  let truckStopCount = getRandomInteger(1, 7);
  let transactionCount = getRandomInteger(1, 7);
  let aSummaries = [];
  let aRows = [];

  aSummaries['galSold'] = 0;
  aSummaries['grossTotal'] = 0;
  aSummaries['dfsTotal'] = 0;
  aSummaries['trxFee'] = 0;
  aSummaries['netTotal'] = 0;

  for(let i = 1;  i <= truckStopCount; i++) {
    let lid = getRandomInteger(1000, 9000);
    let locationName = "Fast Truck Stop #" + lid;
    for (let x = 1; x <= transactionCount; x++) {
      let trxId = (Math.random() + 1).toString(36).substring(7).toUpperCase();
      let id = getRandomInteger(10000, 20000);
      let galSoldFirst = getRandomInteger(120, 180);
      let galSoldLast = getRandomInteger(1, 300);
      let galSold = parseFloat(galSoldFirst + '.' + galSoldLast);
      let retail = fuzzifyNumber(3.658, 0.2, 3);
      let grossTotal = galSold * retail;
      let dfsRate = fuzzifyNumber(2.622, 0.1, 3);
      let dfsTotal = galSold * dfsRate;
      let trxFee = (dfsRate * 0.004) * galSoldFirst;
      let netTotal = dfsTotal - trxFee;

      let tmpStartDate = fuzzyDateTimeStamp(new Date(sStartDate), new Date(sEndDate));
      let tmpEndDate = fuzzyDateTimeStamp(tmpStartDate, new Date(sEndDate));

      let sRow = "<tr style=\u0027background-color: #ffffff;\u0027>" +
        "<td style=\u0027padding: 5px;\u0027>" + lid + "</td>" +
        "<td style=\u0027padding: 5px;\u0027>" + locationName + "</td>" +
        "<td style=\u0027padding: 5px;\u0027>" + trxId + "<\/td>" +
        "<td style=\u0027padding: 5px;\u0027>" + id + "<\/td>" +
        "<td style=\u0027padding: 5px;\u0027>" + tmpStartDate.toLocaleDateString() + ' ' + tmpStartDate.toLocaleTimeString() + "<\/td>" +
        "<td style=\u0027padding: 5px;\u0027>" + tmpEndDate.toLocaleDateString() + ' ' + tmpEndDate.toLocaleTimeString() + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>" + galSold.toFixed(3) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>" + retail + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + grossTotal.toFixed(2) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>" + dfsRate + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + dfsTotal.toFixed(2) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>($" + trxFee.toFixed(2) + ")<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + netTotal.toFixed(2) + "<\/td>" +
        "<\/tr>";

      aRows.push(sRow);
      aSummaries['galSold'] = parseFloat(aSummaries['galSold']) + parseFloat(galSold.toFixed(3));
      aSummaries['grossTotal'] = parseFloat(aSummaries['grossTotal']) + parseFloat(grossTotal.toFixed(2));
      aSummaries['dfsTotal'] = parseFloat(aSummaries['dfsTotal']) + parseFloat(dfsTotal.toFixed(2));
      aSummaries['trxFee'] = parseFloat(aSummaries['trxFee']) + parseFloat(trxFee.toFixed(2));
      aSummaries['netTotal'] = parseFloat(aSummaries['netTotal']) + parseFloat(netTotal.toFixed(2));
    }
  }

  let settlementFee = (5 * truckStopCount).toFixed(2);
  let achTotal = (aSummaries['netTotal'] - settlementFee).toFixed(2);

  let sSummaryRow = "<tr style='background-color: #cccccc;'>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>" + parseFloat(aSummaries['galSold']).toFixed(3) + "</td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['grossTotal']).toFixed(2) + "</td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['dfsTotal']).toFixed(2) + "</td>" +
    "<td style='padding: 5px; text-align: right;'>($" + parseFloat(aSummaries['trxFee']).toFixed(2) + ")</td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['netTotal']).toFixed(2) + "</td>" +
    "</tr>";
  aRows.push(sSummaryRow);

  let sTotalTows = "" +
    "<tr style='font-weight: bold;'>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>Settlement Fee:</tdi>" +
    "<td style='padding: 5px; text-align: right;'>($" + settlementFee + ")</td>" +
    "</tr>" +
    "<tr style='font-weight: bold;'>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>Total ACH:</td>" +
    "<td style='padding: 5px; text-align: right'>$" + achTotal + "</td>" +
    "</tr>";

  aRows.push(sTotalTows)

  return {
    rows: aRows.join('')
  };
}

/**
 * Generate fake report data for Location Weekly Settlements
 * @param sStartDate
 * @param sEndDate
 * @returns {{achTotal: string, rows: string}}
 */
export const generateFakeLocationWeeklySettlementData = (sStartDate, sEndDate) => {
  let transactionCount = getRandomInteger(2, 7),
    aSummaries = [],
    aRows = [];

  aSummaries = {
    galSold: 0,
    grossTotal: 0,
    dfsTotal: 0,
    trxFee: 0,
    netTotal: 0
  };

  for (let i = 1;  i <= transactionCount; i++) {
    let trxId = (Math.random() + 1).toString(36).substring(7).toUpperCase();
    let id = getRandomInteger(10000,20000);
    let galSoldFirst = getRandomInteger(120, 180);
    let galSoldLast = getRandomInteger(1,300);
    let galSold = parseFloat(galSoldFirst + '.' + galSoldLast);
    let retail = 3.858;
    let grossTotal = galSold * retail;
    let dfsRate = 3.573;
    let dfsTotal = galSold * dfsRate;
    let trxFee = (dfsRate * 0.004) * galSoldFirst;
    let netTotal = dfsTotal - trxFee;

    let tmpStartDate = fuzzyDateTimeStamp(new Date(sStartDate), new Date(sEndDate));
    let tmpEndDate = fuzzyDateTimeStamp(tmpStartDate, new Date(sEndDate));

    let sRow = "<tr style=\u0027background-color: #ffffff;\u0027>" +
        "<td style=\u0027padding: 5px;\u0027>" + trxId + "<\/td>" +
        "<td style=\u0027padding: 5px;\u0027>" + id + "<\/td>" +
        "<td style=\u0027padding: 5px;\u0027>" + tmpStartDate.toLocaleDateString() + ' ' + tmpStartDate.toLocaleTimeString() + "<\/td>" +
        "<td style=\u0027padding: 5px;\u0027>" + tmpEndDate.toLocaleDateString() + ' ' + tmpEndDate.toLocaleTimeString() + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>" + galSold.toFixed(3) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>" + retail.toFixed(2) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + grossTotal.toFixed(2) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>" + dfsRate.toFixed(3) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + dfsTotal.toFixed(2) + "<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>($" + trxFee.toFixed(2) + ")<\/td>" +
        "<td style=\u0027padding: 5px;text-align: right;\u0027>$" + netTotal.toFixed(2) + "<\/td>" +
        "<\/tr>";

    aRows.push(sRow);
    aSummaries['galSold'] = parseFloat(aSummaries['galSold']) + parseFloat(galSold.toFixed(3));
    aSummaries['grossTotal'] = parseFloat(aSummaries['grossTotal']) + parseFloat(grossTotal.toFixed(2));
    aSummaries['dfsTotal'] = parseFloat(aSummaries['dfsTotal']) + parseFloat(dfsTotal.toFixed(2));
    aSummaries['trxFee'] = parseFloat(aSummaries['trxFee']) + parseFloat(trxFee.toFixed(2));
    aSummaries['netTotal'] = parseFloat(aSummaries['netTotal']) + parseFloat(netTotal.toFixed(2));
  }

  let sSummaryRow = "<tr style='background-color: #cccccc;'>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>" + parseFloat(aSummaries['galSold']).toFixed(3) + "</td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['grossTotal']).toFixed(2) + "</td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['dfsTotal']).toFixed(2) + "</td>" +
    "<td style='padding: 5px; text-align: right;'>($" + parseFloat(aSummaries['trxFee']).toFixed(2) + ")</td>" +
    "<td style='padding: 5px; text-align: right;'>$" + parseFloat(aSummaries['netTotal']).toFixed(2) + "</td>" +
    "</tr>";
  aRows.push(sSummaryRow);

  let sTotalTows = "" +
    "<tr style='font-weight: bold;'>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>Settlement Fee:</tdi>" +
    "<td style='padding: 5px; text-align: right;'>($5.00)</td>" +
    "</tr>" +
    "<tr style='font-weight: bold;'>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td></td>" +
    "<td style='padding: 5px; text-align: right;'>Total ACH:</td>" +
    "<td style='padding: 5px; text-align: right'>$" + (aSummaries['netTotal'] - 5).toFixed(2) + "</td>" +
    "</tr>";

  aRows.push(sTotalTows)

  return {
    rows: aRows.join(''),
    achTotal: (aSummaries['netTotal'] - 5).toFixed(2)
  };
}

export const generateReportMetadata = (sReportType, sReportSentDate) => {
  let sCompanyName = "Fast Demo Company";
  let sLocationName = "Fast Demo Truck Stop";
  let sRandomId = getRandomInteger(1000,9999);
  let sBody = '';
  let oReturn = {};
  let aData;
  let sEndDate, sStartDate;

  switch (sReportType) {
    case 'dfs_location_weekly_settlement':
      sEndDate = new Date((new Date(sReportSentDate) - ( 5 * 24 * 60 * 60 * 1000))).toISOString().split('T')[0];
      sStartDate = new Date((new Date(sEndDate) - ( 12 * 24 * 60 * 60 * 1000))).toISOString().split('T')[0];
      aData = generateFakeLocationWeeklySettlementData(sStartDate, sEndDate);
      sBody = "<p>Hi,<\/p><p>Here is your weekly DFS Settlement for period: " + sStartDate + " - " + sEndDate + ".<\/p>" +
        "<hr \/>" +
        "<table style=\u0027text-align: left; width: 100%\u0027>" +
        "<tr>" +
        "<td style=\u0027padding: 5px; text-align: left; vertical-align: top; width: 33%;\u0027>" +
        "DFS<br \/>800 W Main St, Ste 1460<br \/>Boise, ID 83702<br \/>(888) 540-2909 \/ (208) 717-0956 fax " +
        "<\/td>" +
        "<td style=\u0027padding: 5px; text-align: left; vertical-align: top; width: 33%;\u0027>" +
        "Weekly Settlement for <b>" + sCompanyName + "<\/b><br \/>Statement No: DFS00" + sRandomId + "<br \/>" +
        "Start Date: " + sStartDate + "<br \/>End Date: " + sEndDate + "<br \/>ACH Date: " + sEndDate + "<br \/>" +
        "ACH Total: $" + aData['achTotal'] +
        "<\/td>" +
        "<td style=\u0027padding: 5px; text-align: right; vertical-align: top; width: 34%;\u0027>" +
        "<b>Remitted To:<\/b><br \/>important_person@fastdemocompany.com, person_of_interest@fastdemocompany.com<br \/>" +
        sLocationName + "<br \/>12345 Imaginary Lane<br \/>Someplace, TA 12345" +
        "<\/td><\/tr><\/table>" +
        "<table style=\u0027width: 100%;\u0027 class=\u0027dfs_data_table\u0027><thead><tr style=\u0027font-weight: bold;\u0027>" +
        "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Trx ID<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "ID<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Reserved<br \/>Date \/ Time<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Payment<br \/>Date \/ Time<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Gal Sold<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Retail<br \/>($ \/ gal)<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Gross Total<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "DFS Rate<br \/>($ \/ gal)<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "DFS Total<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Trx<br \/>Fee<\/th><th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>" +
        "Net Total<\/th><\/tr><\/thead>" +
        "<tbody>" +
        aData['rows'] +
        "<\/tbody>" +
        "<\/table>";
      break;

    case 'dfs_merchant_weekly_summary':
      sEndDate = new Date((new Date(sReportSentDate) - ( 5 * 24 * 60 * 60 * 1000))).toISOString().split('T')[0];
      sStartDate = new Date((new Date(sEndDate) - ( 12 * 24 * 60 * 60 * 1000))).toISOString().split('T')[0];
      aData = generateFakeMerchantWeeklySummaryData(sStartDate, sEndDate);
      sBody = "<p>Hi,<\/p><p>Here is your Merchant Weekly Summary for " + sStartDate + " through " + sEndDate + "<\/p><hr \/>" +
      "<table style=\u0027text-align: left; width: 100%\u0027><tr><td style=\u0027padding: 5px; text-align: left; vertical-align: top; width: 33%;\u0027>DFS<br \/>" +
      "800 W Main St, Ste 1460<br \/>Boise, ID 83702<br \/>(888) 540-2909 \/ (208) 717-0956 fax <\/td>" +
      "<td style=\u0027padding: 5px; text-align: left; vertical-align: top; width: 33%;\u0027>" +
      "Merchant Weekly Summary for <b>" + sCompanyName + "<\/b><br \/>Start Date: " + sStartDate + "<br \/>End Date: " + sEndDate + "<\/td>" +
      "<td style=\u0027padding: 5px; text-align: right; vertical-align: top; width: 34%;\u0027><b>Remitted To:<\/b><br \/>" +
      "important_person@fastfuelcompany.com, person_of_interest@fastfuelcompany.com<br \/>" + sCompanyName+ "<br \/><\/td><\/tr><\/table>" +
      "<table style=\u0027width: 100%;\u0027 class=\u0027dfs_data_table\u0027><thead><tr style=\u0027font-weight: bold;\u0027>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>LID<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Location Name<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Trx ID<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>ID<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Reserved<br \/>Date \/ Time<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Payment<br \/>Date \/ Time<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Gal Sold<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Retail<br \/>($ \/ gal)<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Gross Total<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>DFS Rate<br \/>($ \/ gal)<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>DFS Total<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Trx<br \/>Fee<\/th>" +
      "<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Net Total<\/th><\/tr><\/thead>" +
      "<tbody>" +
      aData['rows'] +
      "</tbody>" +
      "</table>";
      break;

    case 'dfs_location_daily':
      aData = generateFakeLocationDailySummaryData(sReportSentDate);
      let sFormattedDate = new Date(sReportSentDate).toISOString().split('T')[0];
      sBody = '<p>Hi,</p><p>Here is your daily DFS summary for ' + sFormattedDate + '.</p><hr><p></p><br><br><br>' +
        '<table style="text-align: left; width: 100%">' +
        '<tbody><tr><td style="padding: 5px; text-align: left; vertical-align: top;">DFS<br>800 W Main St, Ste 1460<br>Boise, ID 83702<br>(888) 540-2909 / (208) 717-0956 fax </td>' +
        '<td style="padding: 5px; text-align: right; vertical-align: top;">DFS Daily Summary for <b>' + sLocationName + '</b><br>Summary Date: ' + sReportSentDate + '<br><br>' +
        'Gallons Total: ' + aData['galSold'] + '<br />' +
        'Total: $' + aData['dfsTotal'] +
        '</tbody></table>' +
        '<table style=\u0027width: 100%;\u0027 class=\u0027dfs_data_table\u0027>' +
        '<thead><tr style=\u0027font-weight: bold;\u0027>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Trx ID<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>ID<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Reserved<br \/>Date \/ Time<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Payment<br \/>Date \/ Time<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Gal<\/th>'+
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Retail<br \/>($ \/ gal)<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Gross Total<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>DFS Rate<br \/>($ \/ gal)<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>DFS Total<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Trx<br \/>Fee<\/th>' +
        '<th style=\u0027background-color: #dddddd; padding: 5px; text-align: center; border-spacing: 1px; border: 1px solid white;\u0027>Net Total<\/th>' +
        '<\/tr><\/thead><tbody>' +
        aData['rows'] +
        '</tbody></table>';
      break;
  }

  oReturn = {
    body: sBody
  }
  return oReturn;
}

import React, { createContext, useContext, useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
// import { setToken, getDataFromAPI } from 'helpers/api'
import { useAccountContext } from 'context/Account'
import { initialState, NotificationReducer } from '../reducers/notificationReducer'

export const NotificationContext = createContext()
export function NotificationProvider ({ children }) {
  const { appState } = useAccountContext()
  // eslint-disable-next-line
  const [notificationState, dispatch] = useReducer(NotificationReducer, initialState)
  // eslint-disable-next-line
  const [readyState, setReadyState] = useState(false)

  /**
   * Retrieve the device location
   */
  // useEffect(() => {

  //   // if (appState.session != null){
  //     // console.log(appState.session.idToken)

  //   // }
  // }, [])

  useEffect(() => {
    if (
      appState.ready
      && appState.authenticated) {
      setReadyState(appState.ready)
    }
  }, [appState])

  // useEffect(() => {
  //   if (readyState === true) {
  //   }
  // }, [readyState])

  const sharedState = {
    notificationState
  }

  return (
    <NotificationContext.Provider value={sharedState}>
      {children}
    </NotificationContext.Provider>
  )
}

export function useNotificationContext () {
  const state = useContext(NotificationContext)

  if (state === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider')
  }

  return state
}

NotificationProvider.propTypes = {
  children: PropTypes.any,
}

export const dashboardRoutes = {
  label: 'Dashboard',
  id: 'dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      id: 'dashboard_dashboard',
      icon: 'chart-pie',
      to: '/',
      active: true
    }
  ]
}

export const DFSRoutes = {
  label: 'DFS',
  id: 'dfs',
  children: [
    {
      name: 'Transactions',
      id: 'dfs_transactions',
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'Reservations',
          id: 'dfs_transactions_reservations',
          to: '/dfs/reservations',
          active: true,
        },
        {
          name: 'Redemptions',
          id: 'dfs_transactions_redemptions',
          to: '/dfs/redemptions',
          active: true
        }
      ]
    },
    {
      name: 'Reports',
      id: 'dfs_reports',
      icon: 'chart-line',
      active: true,
      children: [
        {
          name: 'Settlements',
          id: 'dfs_reports_settlements',
          to: '/dfs/settlements',
          active: true,
        },
        {
          name: 'Daily Summaries',
          id: 'dfs_reports_daily_summaries',
          to: '/dfs/dailysummaries',
          active: true,
        },
        {
          name: 'Merchant Summaries',
          id: 'dfs_reports_merchant_summaries',
          to: '/dfs/merchantsummary',
          active: true
        }
      ]
    }
  ]
}

export const RewardsRoutes = {
  label: 'Rewards',
  id: 'rewards',
  children: [
    {
      name: 'Transactions',
      id: 'rewards_transactions',
      icon: 'chart-line',
      to: '/documentation/getting-started',
      active: true
    }
  ]
}

export const LocationManagementRoutes = {
  label: 'Location Management',
  id: 'location_management',
  children: [
    {
      name: 'Pricing',
      id: 'location_management_pricing',
      icon: 'sack-dollar',
      active: true,
      children: [
        {
          name: 'Retail',
          id: 'location_management_pricing_retail',
          to: '/pricing',
          active: true,
        }
      ]
    }
  ]
}

export const HelpRoutes = {
  label: 'Help',
  id: 'help',
  labelDisable: true,
  children: [
    {
      name: 'Help',
      id: 'help_help',
      active: true,
      icon: 'question-circle',
      children: [
        // {
        //   name: 'FAQ',
        //   to: '/faq',
        //   active: true
        // },
        // {
        //   name: 'Tutorial',
        //   to: '/tutorial',
        //   active: true
        // },
        {
          name: 'Terms of Service',
          id: 'help_help_terms_of_service',
          to: '/terms',
          active: true
        },
        {
          name: 'Privacy Policy',
          id: 'help_help_privacy_policy',
          to: '/privacy',
          active: true
        }
      ]
    }
  ]
}

export default [
  dashboardRoutes,
  DFSRoutes,
  // RewardsRoutes,
  LocationManagementRoutes,
  // HelpRoutes
]

/**
 * dfs_redemptions/index.jsx
 * Page component used to display the customer's redemptions on a sortable grid, filterable and exportable to csv.
 * @author John Nebel <john@roadyscorp.com>
 */
import React, { useState, useEffect, useContext } from 'react'
import { useLocationContext } from 'context/Location'
import AppContext from 'context/Context'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import PageHeader from 'components/common/PageHeader'
import DataTable from 'react-data-table-component'
import DatePicker from 'react-datepicker'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Skeleton from 'react-loading-skeleton'
import { dateTimeOptions, currencyOptions } from 'helpers/formater'
import { isIterableArray } from 'helpers/utils'

const columns = [
  {
    name: 'Order ID/Code',
    selector: row => row.tp_order_id,
    key: 'tp_order_id',
    sortable: true,
  },
  {
    name: 'Location Name',
    selector: row => row.location_name,
    key: 'location_name',
    sortable: true
  },
  {
    name: 'Pump',
    selector: row => row.pump,
    key: 'pump',
    sortable: true,
    width: '5rem',
  },
  {
    name: 'Gallons',
    selector: row => row.gallons,
    key: 'gallons',
    sortable: true,
    width: '6rem',
  },
  {
    name: 'Completed On',
    selector: row => row.completed_on,
    key: 'completed_on',
    sortable: true,
    cell: (row) => {
      return (
        new Intl.DateTimeFormat('en-SU', dateTimeOptions).format(Date.parse(row.completed_on))
      )
    },
  },
  {
    name: 'Retail',
    selector: row => row.retail,
    key: 'retail',
    sortable: true,
    width: '6rem',
    cell: (row) => {
      return (
        new Intl.NumberFormat('default', currencyOptions).format(row.retail)
      )
    },
  }
]

/**
 * Creates the display for the DFSRedemptionsPage, the component that encompasses the page as a whole..
 * @returns {JSX.Element}
 * @constructor
 */
const DFSRedemptionsPage = () => {
  const {
    config: { isDark }
  } = useContext(AppContext)
  // Let's get the location context up in here.
  const { locationState, getDFSData } = useLocationContext()
  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  // Set up our local state and handlers.
  const [aLocations, setLocations] = useState([])
  const [aReportData, setReportData] = useState([])
  const [bLoading, setLoading] = useState(false)

  // Set up some default start and end dates, and their handlers.
  const today = new Date()
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const sDefaultStartDate = new Date(firstDay)
  const sDefaultEndDate = new Date(lastDay)
  const [sStartDate, setStartDate] = useState(sDefaultStartDate)
  const [sEndDate, setEndDate] = useState(sDefaultEndDate)

  /**
   * When the active view changes, or the company/location data
   * changes, we want to update our local states, so we have things to work
   * with.
   */
  useEffect(() => {
    if (locationState.company !== null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.dfs.enabled) {
                return l
              }
              return null
            })
          }).flat().map(l => {
            return l.location_id
          }))
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.dfs.enabled) {
                return l
              }
              return null
            }).map(l => {
              return l.location_id
            }))
          break
        case 'l':
          setLocations([activeData.id])
          break
      }
    }
  }, [activeData, locationState.company])

  /**
   * When the activeData, sStartDate, or sEndDate are updated, do these things!
   */
  useEffect(() => {
    // If we have locations, lets do things.
    if (activeData !== null && isIterableArray(aLocations)) {
      // Set up a default set of filters
      const oFilters = {
        dateRange: {
          start: sStartDate,
          end: sEndDate
        },
        reportType: 'redemptions'
      }

      // Get the data and do something with it.
      setLoading(true)
      getDFSData(aLocations, oFilters).then(function (data) {
        if (data) {
          setReportData(data)
        } else {
          setReportData(null)
        }
      })
    }
    // }
  }, [activeData, aLocations, sStartDate, sEndDate])

  /**
   * When the report data has been updated, do these things.
   */
  useEffect(() => {
    setLoading(false)
    // console.log('The report data has been updated.')
  }, [aReportData])

  /**
   * Converts an array of objects to CSV
   * @param {array} array - the data
   * @param aCustomHeaders
   * @returns {string}
   */
  function convertArrayOfObjectsToCSV (array, aCustomHeaders = []) {
    let result
    const columnDelimiter = ','
    const lineDelimiter = '\n'
    const keys = Object.keys(array[0])

    result = ''

    if (isIterableArray(aCustomHeaders)) {
      result += aCustomHeaders.join(columnDelimiter)
    } else {
      result += keys.join(columnDelimiter)
    }

    result += lineDelimiter

    array.forEach(item => {
      let ctr = 0
      keys.forEach(key => {
        if (ctr > 0) {
          result += columnDelimiter
        }
        result += item[key]
        ctr++
      })
      result += lineDelimiter
    })
    return result
  }

  /**
   * downloads CSV action
   */
  function exportCSV () {
    if (aReportData && isIterableArray(aReportData)) {
      const link = document.createElement('a')
      const aReducedReportData = reduceReportData(aReportData, columns)

      // Make our custom headers
      const aCustomHeaders = []
      columns.forEach(function (column) {
        aCustomHeaders.push(column.name)
      })

      // Generate our csv.
      let csv = convertArrayOfObjectsToCSV(aReducedReportData, aCustomHeaders)

      if (csv) {
        const filename = 'redemptions_export.csv'

        if (!csv.match(/^data:text\/csv/i)) {
          csv = `data:text/csv;charset=utf-8,${csv}`
        }

        link.setAttribute('href', encodeURI(csv))
        link.setAttribute('download', filename)
        link.click()
      } else {
        toast.error(
          'There was an error generating your report.  Please contact support for further assistance.',
          {
            autoClose: false,
          }
        )
      }
    } else {
      toast.error(
        'There is no data in this report, try modifying your report parameters.',
        {
          autoClose: false,
        }
      )
    }
  }

  /**
   * Take an array and reduce it down to just the columns we care about.
   * @param aData
   * @param oColumns
   */
  function reduceReportData (aData, oColumns) {
    const aReducedArray = []

    // Get the data rows in.
    aData.forEach(function (oRow) {
      const oReducedRow = {}
      oColumns.forEach(function (oColumn) {
        oReducedRow[oColumn.key] = oRow[oColumn.key]
      })
      aReducedArray.push(oReducedRow)
    })

    // Return the thing!
    return aReducedArray
  }

  // Set up the page header.
  const oPageHeader = (
    <PageHeader
      title='DFS Redemptions'
      description=""
      className='mb-3'
    />
  )

  // Set up any additional controls
  const oControls = (
    <Nav className='mb-2'>
      <Nav.Item>
        <Container>
          <Row>
            <Col>
              <label>Start Date:</label>
            </Col>
            <Col>
              <DatePicker portalId={'startDatePickerPortal'}
                          selected={sStartDate}
                          onChange={(date) => setStartDate(date)} />
            </Col>
          </Row>
        </Container>
      </Nav.Item>
      <Nav.Item>
        <Container>
          <Row>
            <Col>
              <label>End Date:</label>
            </Col>
            <Col>
              <DatePicker portalId={'endDatePickerPortal'}
                          selected={sEndDate}
                          onChange={(date) => setEndDate(date)} />
            </Col>
          </Row>
        </Container>
      </Nav.Item>
      <Nav.Item className="ms-auto">
        <Button onClick={exportCSV} className={'btn btn-sm'}>Export CSV</Button>
      </Nav.Item>
    </Nav>
  )

  // Set up the actual DataTable
  let oDataTable
  if (bLoading === true) {
    oDataTable = (
      <Skeleton count={10}/>
    )
  } else {
    if (aReportData && isIterableArray(Object.keys(aReportData))) {
      oDataTable = (
        <Row>
          <Col>
            <DataTable
              columns={columns}
              data={aReportData}
              pagination={true}
              striped={true}
              responsive={true}
              fixedHeader={true}
              theme={isDark ? 'custom-dark' : 'light'}
            />
          </Col>
        </Row>
      )
    } else {
      oDataTable = (
        <Row>
          <Col>
            <p className='text-center'>No data to display.</p>
          </Col>
        </Row>
      )
    }
  }

  // Send it all back!
  return (
    <>
      {oPageHeader}
      {oControls}
      {oDataTable}
    </>
  )
}

export default DFSRedemptionsPage

import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'

import LocationsOverview from './LocationsOverview'
import DfsGallonsPerDay from './DfsGallonsPerDay'
import { useFeatureFlagsContext } from 'context/FeatureFlags'

const CompanyBoard = () => {
  const { featureFlagsState } = useFeatureFlagsContext()
  const [currentFeatureFlagsState, setCurrentFeatureFlagsState] = useState(null)
  const [hasDFS, setHasDFS] = useState(false)

  useEffect(() => {
    setCurrentFeatureFlagsState(featureFlagsState)
  }, [featureFlagsState])

  useEffect(() => {
    if (
      currentFeatureFlagsState !== null
      && Object.prototype.hasOwnProperty.call(currentFeatureFlagsState, 'dfs')
    ) {
      setHasDFS(currentFeatureFlagsState.dfs.enabled)
    }
  }, [currentFeatureFlagsState])

  return (
    <>
      <Row className={'g3 mb-3'}>
        <Col lg={12} >
          <LocationsOverview />
        </Col>
      </Row>
      {
        // DFS gallons per day widget.
        hasDFS
          ? <Row className={'g3 mb-3'}>
            <Col lg={12}>
              <DfsGallonsPerDay />
            </Col>
          </Row>
          : ''
      }
    </>
  )
}

export default CompanyBoard

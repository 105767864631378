import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import NavbarTop from 'components/navbar/top'
import AppContext from 'context/Context'
import { LocationProvider } from 'context/Location'
import classNames from 'classnames'

const UnconfiguredAppLayout = () => {
  const { hash, pathname } = useLocation()
  const isKanban = pathname.includes('kanban')

  const {
    config: { isFluid }
  } = useContext(AppContext)

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    }, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      <LocationProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          <NavbarTop />
          {/* ------ Main Routes ------ */}
          <Outlet />
          {/* {!isKanban && <Footer />} */}
        </div>
      </LocationProvider>
    </div>
  )
}

export default UnconfiguredAppLayout

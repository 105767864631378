import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  Row,
  Col,
  Badge,
  Tooltip,
  OverlayTrigger
} from 'react-bootstrap'
import Flex from 'components/common/Flex'
import { currencyOptions, dateTimeOptions } from 'helpers/formater'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import FalconCardHeader from 'components/common/FalconCardHeader'
import { isIterableArray } from 'helpers/utils'
import { useLocationContext } from 'context/Location'

const oldestDate = new Date()
oldestDate.setDate(oldestDate.getDate() - 1)

const LocationItem = ({ retail_price: retail, retail_date: date, location_name: name, location_id: id }) => {
  const retailPrice = new Intl.NumberFormat('default', currencyOptions).format(retail)
  const { setActiveViewSet } = useLocationContext()

  let retailPriceDate
  const retailDate = new Date(date)

  if (date) {
    retailPriceDate = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(retailDate)
  }

  const handleLocationChange = id => {
    setActiveViewSet(id)
  }

  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        className="mb-3 position-static"
      >
        <h6 className="mb-0 flex-1">{name} ({id})</h6>
      </Flex>
    <div style={ { marginBottom: '1rem' } }
      onClick={ e => handleLocationChange(id) }
      className={
          classNames({
            'price-outdated card-data': retailDate.getTime() < oldestDate.getTime()
          })
        }>
        <h2 className="fw-normal text-700 mb-1 lh-1">{retailPrice}</h2>
        <OverlayTrigger
          placement='bottom'
          overlay={
            <Tooltip>
              Retail price last changed date
            </Tooltip>
          }
        >
          <Badge pill bg='200' className='text-primary fs--2'>
              {retailPriceDate}
          </Badge>
        </OverlayTrigger>
      </div>
    </>
  )
}

LocationItem.propTypes = {
  retail_price: PropTypes.number,
  retail_date: PropTypes.string,
  location_name: PropTypes.string,
  location_id: PropTypes.number,
}

LocationItem.defaultProps = {
  retail_date: '0',
  retail_price: 0
}

const LocationsOverview = () => {
  const { locationState } = useLocationContext()
  const [locations, setLocations] = useState(null)
  const [activeData, setActiveData] = useState(null)

  useEffect(() => {
    if (locationState.active_view !== null) {
      setActiveData(locationState.active_view)
    }
  }, [locationState.active_view])

  useEffect(() => {
    if (locationState.company !== null && activeData !== null) {
      switch (activeData.type) {
        case 'u':
          setLocations(locationState.company.map(c => {
            return c.locations.filter(l => {
              if (l.options.dfs.enabled) {
                return l
              }
              return null
            })
          }).flat())
          break
        case 'c':
          setLocations(locationState.company.filter(c => {
            if (c.companyid === activeData.id) {
              return c.locations
            }
            return null
          })[0]?.locations
            .filter(l => {
              if (l.options.dfs.enabled) {
                return l
              }
              return null
            }))
          break
      }
    }
  }, [locationState.company, activeData])

  return (
    <Card>
      <FalconCardHeader
        title='DFS Location Retail Prices'
        titleTag='h4'
        className='pb-0'
      >
      </FalconCardHeader>
      {locations === null
        ? <Card.Body>
              <Skeleton count={4} />
          </Card.Body>
        : <Card.Body>
          <Row>
            {isIterableArray(locations)
              ? locations.map((l, i) => (
                  <Col
                    lg={4}
                    key={l.location_id}
                    className={classNames({
                      'border-bottom border-lg-0 border-lg-end': i !== locations.length - 1,
                      'pb-3 pb-lg-0': i === 0,
                      'py-3 py-lg-0': i === 1,
                      'pt-3 pt-lg-0': i === 2
                    })}
                  >
                    <LocationItem {...l} />
                  </Col>
              ))
              : <Row>
                <Col>No DFS locations found</Col>
              </Row>
            }
          </Row>
        </Card.Body>
      }
    </Card>
  )
}

export default LocationsOverview

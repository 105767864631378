import React, { createContext, useContext, useState, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { getDataFromAPI } from 'helpers/api'
import { useAccountContext } from 'context/Account'
import { initialState, FeatureFlagsReducer } from '../reducers/featureFlagsReducer'
import * as demoData from 'data/demo'

export const FeatureFlagsContext = createContext()

export function FeatureFlagsProvider ({ children }) {
  const { appState } = useAccountContext()
  const [featureFlagsState, dispatch] = useReducer(FeatureFlagsReducer, initialState)
  const [readyState, setReadyState] = useState(false)
  // eslint-disable-next-line
  const [featureFlagsDetails, setFeatureFlagsDetails] = useState(null)

  useEffect(() => {
    // console.log('appState.ready: ' + appState.ready)

    setReadyState(appState.ready)
  }, [appState.ready])

  useEffect(() => {
    // console.log(appState)
    // console.log('readyState: ' + readyState)
    if (readyState === true) {
      getFeatureFlags()

      // setInterval(() => {
      //   getFeatureFlags()
      // }, 900000)
      // dispatch({
      //   type: 'UPDATE_PROFILE',
      //   payload: JSON.parse(localStorage.getItem('profile_data')),
      // })
      // setLastUpdate(localStorage.getItem('last_profile_data_update'))
    }
  }, [readyState])

  async function getFeatureFlags () {
    if (process.env.REACT_APP_ENV !== 'demo') {
      const details = await getDataFromAPI('/config')
      if (details !== undefined && details.status === 200) {
        setFeatureFlagsDetails(details.data)
        dispatch({
          type: 'SET_CONFIG',
          payload: details.data,
        })
      }
    } else {
      setFeatureFlagsDetails(demoData.config)
      dispatch({
        type: 'SET_CONFIG',
        payload: demoData.config,
      })
    }
  }

  const sharedState = {
    featureFlagsState
  }

  return (
    <FeatureFlagsContext.Provider value={sharedState}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export function useFeatureFlagsContext () {
  const state = useContext(FeatureFlagsContext)

  if (state === undefined) {
    throw new Error('useFeatureFlagsContext must be used within a FeatureFlagsProvider')
  }

  return state
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.any,
}

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useAccountContext } from 'context/Account'

const PasswordResetForm = ({ hasLabel, user }) => {
  const { updatePassword } = useAccountContext()

  const [isProcessing, setProcessing] = useState(false)
  const [isSubmitable, setIsSubmitable] = useState(false)
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  })

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    if (formData.password !== '' && formData.password === formData.confirmPassword) {
      setIsSubmitable(true)
    } else {
      setIsSubmitable(false)
    }
  }, [formData])

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault()
    setProcessing(true)

    try {
      // eslint-disable-next-line
      const data = await updatePassword(user, formData.password)
      // console.log(data)
      // success(user)
    } catch (error) {
      setProcessing(false)
      // console.log('error signing up:', error)
      toast.error(
        'We\'re having troubles processing your request! Please try again later.',
        {
          position: 'top-left',
          autoClose: false,
          theme: 'colored',
        }
      )
    }
  }

  return (
    <Form onSubmit={handleSubmit}>

      <Form.Group className='mb-3'>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            name='password'
            onChange={handleFieldChange}
            type='password'
          />
      </Form.Group>

      <Form.Group className='mb-3'>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name='confirmPassword'
            onChange={handleFieldChange}
            type='password'
          />
      </Form.Group>

      <Form.Group>
          <Button
            type='submit'
            color='primary'
            className='mt-3 w-100'
            disabled={!isSubmitable}
          >
            {isProcessing
              ? (
                <FontAwesomeIcon
                  icon='circle-notch'
                  className='me-1 fa-spinner fa-spin'
                />
                )
              : (
                  ''
                )}{' '}
            Update Password
          </Button>
      </Form.Group>

    </Form>
  )
}

PasswordResetForm.propTypes = {
  user: PropTypes.object,
  hasLabel: PropTypes.bool
}

PasswordResetForm.defaultProps = {
  hasLabel: false
}

export default PasswordResetForm

import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import NavbarTop from 'components/navbar/top'
import NavbarVertical from 'components/navbar/vertical'
import AppContext from 'context/Context'
import { LocationProvider } from 'context/Location'
import { NotificationProvider } from 'context/Notification'
import Footer from 'components/footer'
import classNames from 'classnames'

const MainLayout = () => {
  const { hash, pathname } = useLocation()
  const isKanban = pathname.includes('kanban')

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext)

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    }, 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <NotificationProvider>
      <LocationProvider>
        <div className={isFluid ? 'container-fluid' : 'container'}>
          {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
            <NavbarTop />
          )}
          <NavbarVertical />
          <div className={classNames('content', { 'pb-0': isKanban })}>
            {/* <NavbarTop /> */}
            {/* ------ Main Routes ------ */}
            <Outlet />
            {process.env.REACT_APP_ENV !== 'prod'
              ? <Footer />
              : ''
            }
          </div>
        </div>
      </LocationProvider>
    </NotificationProvider>
  )
}

export default MainLayout

import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'

// import { totalSales } from 'data/dashboard/default'
// import MonthlyDFS from './LocationMonthlyDFS'
import CurrentRetail from './LocationCurrentRetail'
// import DfsSummary from './LocationDfsSummary'

import { useFeatureFlagsContext } from 'context/FeatureFlags'
import DfsGallonsPerDay from './DfsGallonsPerDay'

const LocationBoard = () => {
  const { featureFlagsState } = useFeatureFlagsContext()
  const [currentFeatureFlagsState, setCurrentFeatureFlagsState] = useState(null)
  const [hasDFS, setHasDFS] = useState(false)

  useEffect(() => {
    setCurrentFeatureFlagsState(featureFlagsState)
  }, [featureFlagsState])

  useEffect(() => {
    if (
      currentFeatureFlagsState !== null
      && Object.prototype.hasOwnProperty.call(currentFeatureFlagsState, 'dfs')
    ) {
      setHasDFS(currentFeatureFlagsState.dfs.enabled)
    }
  }, [currentFeatureFlagsState])

  return (
    <>
      <Row className='g-3 mb-3'>
        {/* { */}
        {/*  hasDFS */}
        {/*    ? */}
        {/*    <Col md={6} xxl={3}> */}
        {/*      <DfsSummary /> */}
        {/*    </Col> */}
        {/*    : '' */}
        {/* } */}
        <Col md={6} xxl={3}>
          <CurrentRetail />
        </Col>
      </Row>

      {/* { */}
      {/*  // DFS monthly DFS sales */}
      {/*  hasDFS */}
      {/*    ? <Row className='g-3 mb-3'> */}
      {/*      <Col lg={12}> */}
      {/*        <MonthlyDFS data={totalSales} /> */}
      {/*      </Col> */}
      {/*    </Row> */}
      {/*    : '' */}
      {/* } */}

      {
        // DFS gallons per day widget.
        hasDFS
          ? <Row className={'g3 mb-3'}>
          <Col lg={12}>
            <DfsGallonsPerDay />
          </Col>
          </Row>
          : ''
      }
    </>
  )
}

export default LocationBoard

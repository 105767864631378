import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import createMarkup from 'helpers/createMarkup'
// import Avatar from 'components/common/Avatar'
export const dateTimeOptions = {
  year: 'numeric',
  month: 'numeric',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: true,
  timeZoneName: 'short'
}

const Notification = ({
  // avatar,
  time,
  className,
  unread,
  flush,
  // emoji,
  children
}) => {
  const eventDate = new Date(time)
  const eventDateFormatted = new Intl.DateTimeFormat('en-US', dateTimeOptions).format(eventDate)

  return (
    <Link
      className={classNames(
        'notification',
        { 'notification-unread': unread, 'notification-flush': flush },
        className
      )}
      to='#!'
    >
      {/* {avatar && (
        <div className='notification-avatar'>
          <Avatar {...avatar} className='me-3' />
        </div>
      )} */}
      <div className='notification-body'>
        <p className='mb-1' dangerouslySetInnerHTML={createMarkup(children)} />
        <span className='notification-time'>
          {/* {emoji && (
            <span className='me-2' role='img' aria-label='Emoji'>
              {emoji}
            </span>
          )} */}
          {eventDateFormatted}
        </span>
      </div>
    </Link>
  )
}

Notification.propTypes = {
  // avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  // emoji: PropTypes.string,
  children: PropTypes.node
}

Notification.defaultProps = { unread: false, flush: false }

export default Notification
